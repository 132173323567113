<template>
    <div class="BoothAdvertisingAdd">
        <div class="BoothAdvertisingAddHeader">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>展位</el-breadcrumb-item>
                <el-breadcrumb-item>推广管理</el-breadcrumb-item>
                <el-breadcrumb-item>新建计划</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="BoothAdvertisingAdd-box">
            <div class="BoothAdvertisingAdd-left">
                <div class="left-creation">
                    <p>01 <span>创建计划</span></p>
                    <div class="left-step">
                        <div class="stepOne">
                            <span class="stepActive"></span>
                            <span>推广首页</span>
                        </div>
                        <div :class="stepActive>1?'stepXian xianActive':'stepXian'">
                            <span v-show="stepActive > 1">店铺推广</span>
                            <span v-show="stepActive > 1" @click="setOne">修改</span>
                        </div>
                        <div class="stepOne">
                            <span :class="stepActive >1 ? 'stepActive' : ''"></span>
                            <span>计划设置</span>
                        </div>
                        <div v-show="stepActive>2" class="stepXian" style="border: none;padding-bottom: 0">
                            <span>统一日预算：{{budget}}</span>
                            <span @click="setTwo">修改</span>
                        </div>

                    </div>
                    <div class="left-stepTwo">
                        <p style="padding-top: 40px">02 <span>创建单元</span></p>
                        <div class="left-step">
                            <div class="stepOne">
                                <span :class="stepActive >2 ? 'stepActive' : ''"></span>
                                <span>出价设置</span>
                            </div>
                            <div :class="stepActive>2?'stepXian xianActive':'stepXian'"></div>
                            <div class="stepOne">
                                <span :class="stepActive >2 ? 'stepActive' : ''"></span>
                                <span>资源位</span>
                            </div>
                            <div :class="stepActive>2?'stepXian xianActive':'stepXian'"></div>
                            <div class="stepOne">
                                <span :class="stepActive >2 ? 'stepActive' : ''"></span>
                                <span>定向设置</span>
                            </div>
                        </div>
                    </div>
                    <div class="left-succeed">
                        <p style="padding-top: 40px">03 <span>完成推广</span></p>
                    </div>
                </div>
            </div>
            <div class="BoothAdvertisingAdd-right">
                <keep-alive>
                    <router-view></router-view>
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BoothAdvertisingAdd",
        data(){
            return {
                stepActive:1,
                budget:''
            }
        },
        created() {
            this.refreshPageRoute();
            if(this.$lockr.get('onePlanTwo')){
                let budgetData = this.$lockr.get('onePlanTwo');
                    if(budgetData.budget == 0){
                        this.budget = '不限'
                    } else {
                        this.budget = budgetData.budget
                    }
            }
        },
        watch: {
            $route(to,from){
                // console.log(88,to)
                // console.log(88,from)
                // return
                if(to.name === 'PlanStepTwo'){
                    this.stepActive = 2
                } else if(to.name === 'PlanStepOne'){
                    this.stepActive = 1;
                } else if(to.name === 'PlanStepThree'){
                    this.stepActive = 3;
                }
                if(this.$lockr.get('onePlanTwo')){
                    let budgetData = this.$lockr.get('onePlanTwo');
                    if(budgetData.budget  == 0){
                        this.budget = '不限'
                    } else {
                        this.budget = budgetData.budget
                    }
                }
                // if(from.name == 'PlanStepSucceed' && to.name == 'PlanStepThree'){
                //     // location.reload()
                //     this.$router.go(0)
                // }
            }
        },
        methods: {
            refreshPageRoute(){
                if(this.$route.name === 'PlanStepTwo'){
                    this.stepActive = 2
                } else if(this.$route.name === 'PlanStepOne'){
                    this.stepActive = 1;
                } else if(this.$route.name === 'PlanStepThree' || this.$route.name === 'PlanStepSucceed'){
                    this.stepActive = 3;
                }
            },
            setOne(){
                if(this.$route.query.editId){
                    this.$router.push({
                        path:'/student/operationpromotion/boothadvertisingone',
                        query:{
                            editId:this.$route.query.editId
                        }
                    })
                } else {
                    this.$router.push({
                        path:'/student/operationpromotion/boothadvertisingone'
                    })
                }
            },
            setTwo(){
                if(this.$route.query.editId){
                    this.$router.push({
                        path:'/student/operationpromotion/boothadvertisingtwo',
                        query:{
                            editId:this.$route.query.editId
                        }
                    })
                } else {
                    this.$router.go(-1)
                    // this.$router.push({
                    //     path:'/student/operationpromotion/boothadvertisingtwo'
                    // })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .stepActive{
        background: #20D08C;
        border-color: #20D08C!important;
    }
    .xianActive{
        border-color: #20D08C!important;
    }
    .BoothAdvertisingAdd{
        height: 100%;
        /*padding: 20px 40px;*/
        background: #F7F9FB;
        .BoothAdvertisingAddHeader{
            span{
                font-size: 16px;
            }
        }
        .BoothAdvertisingAdd-box{
            display: flex;
            .BoothAdvertisingAdd-left{
                width: 190px;
                .left-creation{
                    display: flex;
                    flex-direction: column;
                    p:nth-child(1){
                        margin: 0;
                        padding: 20px 0;
                        line-height:1;
                        color: #333333;
                        font-size: 48px;
                        span{
                            font-size: 24px;
                        }
                    }
                    .left-step{
                        .stepOne{
                            line-height: 0;
                            display: flex;
                            align-items: center;
                            padding-left: 10px;
                            span{
                                display: inline-block;
                            }
                            span:nth-child(1){
                                width: 14px;
                                height: 14px;
                                border: 1px solid #DFDFDF;
                                border-radius: 50%;
                            }
                            span:nth-child(2){
                                margin-left: 16px;
                                font-size: 20px;
                            }
                        }
                        .stepXian{
                            border-left:1px solid #DFDFDF;
                            margin-left: 17px;
                            line-height: 1;
                            padding-left: 23px;
                            padding-top: 10px;
                            display: flex;
                            flex-direction: column;
                            padding-bottom: 30px;
                            span:nth-child(1){
                                color: #333333;
                                font-size: 14px;
                            }
                            span:nth-child(2){
                                cursor: pointer;
                                margin-top: 6px;
                                color: #1E63F1;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .BoothAdvertisingAdd-right{
                width: calc(100% - 230px);
                /*height: 100%;*/
                background: #ffffff;
                margin-top: 20px;
            }
        }
    }
</style>